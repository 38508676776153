import React, {useState} from 'react';
import DocumentTitle from 'react-document-title';
import {Timeline} from "antd";
// import {ClockCircleOutlined} from '@ant-design/icons';


function Index() {
    const [data, setData] = useState([]);
    const [modalVisit, setModalVisit] = useState(true);
    if (modalVisit) {
        fetch('https://bill-rich-1305942110.cos.ap-guangzhou.myqcloud.com/roadmap-2.json')
            .then(res => res.json())
            .then(data => setData(data))
        setModalVisit(false);
    }
    return (
        <DocumentTitle title="小青账-版本规划">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>版本规划-V2</h1>
                    <br/>
                    <p>引言</p>
                    <br/>
                    <p>小青账部分功能处于隐藏状态，需要耐心发现和使用才会有所发觉。</p>
                    <p>希望有能力的同学伙伴来帮助小青账成长，可向小青账官方提供可供其他伙伴参考的文档或者说明。后续活动或者礼物享有优先获取权力。（请关注微信公众号：小青账）</p>
                    <br/>
                    <Timeline>
                        {/*<Timeline.Item dot={<ClockCircleOutlined style={{fontSize: '16px'}}/>}>*/}
                        {/*    新功能开发中~ 敬请期待。*/}
                        {/*</Timeline.Item>*/}
                        {data.map(value => {
                            return <Timeline.Item color="green">
                                <p><h3>{value.version}</h3></p>
                                {value.optimize != null && value.optimize.length > 0 ? <p><h4>优化：</h4></p> : ""}
                                {value.optimize.map(optimizeData => {
                                    return <p>{optimizeData}</p>
                                })}
                                <br/>
                                {value.newFunction != null && value.newFunction.length > 0 ? <p><h4>新功能：</h4></p> : ""}
                                {value.newFunction.map(newFunctionData => {
                                    return <p>{newFunctionData}</p>
                                })}
                            </Timeline.Item>
                        })}
                    </Timeline>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
