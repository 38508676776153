import React from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import {Button} from 'antd';
import BannerSVGAnim from './component/BannerSVGAnim';

function Banner(props) {
    return (
        <div className="banner-wrapper">
            {props.isMobile && (
                <TweenOne animation={{opacity: 1}} className="banner-image-wrapper">
                    <div className="home-banner-image">
                        <img
                            alt="banner"
                            src="https://gw.alipayobjects.com/zos/rmsportal/rqKQOpnMxeJKngVvulsF.svg"
                            width="100%"
                        />
                    </div>
                </TweenOne>
            )}
            <QueueAnim className="banner-title-wrapper" type={props.isMobile ? 'bottom' : 'right'}>
                <div key="line" className="title-line-wrapper">
                    <div
                        className="title-line"
                        style={{transform: 'translateX(-64px)'}}
                    />
                </div>
                <h1 key="h1">小青账-助你实现财务自由</h1>
                <p key="content">
                    距离有钱的生活只差记一笔
                </p>
                <div key="button" className="button-wrapper">
                    <a href="#pic1" rel="noopener noreferrer">
                        <Button type="primary">
                            查看介绍
                        </Button>
                    </a>
                    <a href="https://apps.apple.com/cn/app/%E5%B0%8F%E9%9D%92%E8%B4%A6%E8%AE%B0%E8%B4%A6-%E5%AD%98%E9%92%B1%E8%AE%B0%E8%B4%A6%E6%9C%AC%E7%90%86%E8%B4%A2%E8%B4%AD%E7%89%A9%E6%B8%85%E5%8D%95/id1565700187" target="_blank" rel="noopener noreferrer">
                        <Button style={{margin: '0 16px'}} type="primary" ghost>
                            App Store下载
                        </Button>
                    </a>
                    <a href="/androidDownload" target="_blank" rel="noopener noreferrer">
                        <Button style={{margin: '0 16px'}} type="primary" ghost>
                            安卓下载
                        </Button>
                    </a>
                </div>
            </QueueAnim>
            {!props.isMobile && (
                <TweenOne animation={{opacity: 1}} className="banner-image-wrapper">
                    <BannerSVGAnim/>
                </TweenOne>
            )}
        </div>
    );
}

Banner.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

export default Banner;
