import React from 'react';
import { Row, Col, Icon, Menu, Button, Popover } from 'antd';

import { enquireScreen } from 'enquire-js';

// const LOGO_URL = 'http://leek.purgeteam.com/static/hi.7d4f8932.png';

class Header extends React.Component {
  state = {
    menuVisible: false,
    menuMode: 'horizontal',
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? 'inline' : 'horizontal' });
    });
  }

  render() {
    const { menuMode, menuVisible } = this.state;

    const menu = (
      <Menu mode={menuMode} id="nav" key="nav">
        <Menu.Item key="home">
          <a href="/">首页</a>
        </Menu.Item>
        {/*<Menu.Item key="docs">*/}
        {/*  <a><span>文档</span></a>*/}
        {/*</Menu.Item>*/}
        <Menu.Item key="components">
          <a href="/roadmap">版本规划</a>
        </Menu.Item>
        {
          menuMode === 'inline' && (
            <Menu.Item key="preview">
              <a target="_blank" href="https://apps.apple.com/cn/app/%E5%B0%8F%E9%9D%92%E8%B4%A6%E8%AE%B0%E8%B4%A6-%E5%AD%98%E9%92%B1%E8%AE%B0%E8%B4%A6%E6%9C%AC%E7%90%86%E8%B4%A2%E8%B4%AD%E7%89%A9%E6%B8%85%E5%8D%95/id1565700187/" rel="noopener noreferrer">
                开始使用
              </a>
            </Menu.Item>
          )
        }
      </Menu>
    );

    return (
      <div id="header" className="header">
        {menuMode === 'inline' ? (
          <Popover
            overlayClassName="popover-menu"
            placement="bottomRight"
            content={menu}
            trigger="click"
            visible={menuVisible}
            arrowPointAtCenter
            onVisibleChange={this.onMenuVisibleChange}
          >
            <Icon
              className="nav-phone-icon"
              type="menu"
              onClick={this.handleShowMenu}
            />
          </Popover>
        ) : null}
        <Row>
          <Col xxl={4} xl={5} lg={8} md={8} sm={24} xs={24}>
            <a href="/">
              <div id="logo" to="/">
                {/*<img src={LOGO_URL} alt="logo" />*/}
                <span>小青账-助你实现财务自由</span>
              </div>
            </a>
          </Col>
          <Col xxl={20} xl={19} lg={16} md={16} sm={0} xs={0}>
            <div className="header-meta">
              <div id="preview">
                <a
                  id="preview-button"
                  target="_blank"
                  href="https://apps.apple.com/cn/app/%E5%B0%8F%E9%9D%92%E8%B4%A6%E8%AE%B0%E8%B4%A6-%E5%AD%98%E9%92%B1%E8%AE%B0%E8%B4%A6%E6%9C%AC%E7%90%86%E8%B4%A2%E8%B4%AD%E7%89%A9%E6%B8%85%E5%8D%95/id1565700187/"
                  rel="noopener noreferrer"
                >
                  <Button>
                    开始使用
                  </Button>
                </a>
              </div>
              {menuMode === 'horizontal' ? <div id="menu">{menu}</div> : null}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Header;
